// NOTE: header labels are stored in the database <db-name>.flow.work_order_list_column
const WO_COLUMNS_LEFT = [
  {
    id: 'photo',
    isSortable: false,
    label: 'Photo',
    isVisible: true,
    style: { minWidth: 'auto', paddingRight: 0 },
  },
  { id: 'jobId', isSortable: true, label: 'Job ID', isVisible: true, style: { minWidth: 'auto' } },
  { id: 'publicId', isSortable: true, label: 'Work Order ID', isVisible: true, style: { minWidth: 'auto' } },
  { id: 'deviceStatus', isSortable: true, label: 'Device Status', isVisible: true },
  { id: 'makeTitle', isSortable: true, label: 'Make', isVisible: true },
  { id: 'modelTitle', isSortable: true, label: 'Model', isVisible: true },
  { id: 'categoryTitle', isSortable: true, label: 'Category', isVisible: true },
  { id: 'woStatus', isSortable: true, label: 'Job Status', isVisible: true },
  { id: 'lastActivity', isSortable: true, label: 'Last Action', isVisible: true },
  { id: 'schedule', isSortable: true, label: 'Schedule', isVisible: true },
];

const WO_COLUMNS_RIGHT = [
  { id: 'facilityTitle', isSortable: true, label: 'Facility', isVisible: true },
  { id: 'areaName', isSortable: false, label: 'Area Name', isVisible: true },
];

export const WO_OPEN_HEADERS = [
  ...WO_COLUMNS_LEFT,
  { id: 'createdAt', isSortable: true, label: 'Days Open', isVisible: true },
  ...WO_COLUMNS_RIGHT,
];

export const WO_COMPLETED_HEADERS = [
  ...WO_COLUMNS_LEFT,
  {
    id: 'daysToComplete',
    isSortable: true,
    label: 'Days to Close',
    isVisible: true,
    style: { minWidth: 135, maxWidth: 145 },
  },
  ...WO_COLUMNS_RIGHT,
];

export const INITIALIZED_STATUS = 'initialized';
export const SERVICE_REQUESTED_STATUS = 'serviceRequested';
export const IN_PROGRESS_STATUS = 'inProgress';
export const SCHEDULED_STATUS = 'scheduled';
export const ON_REVIEW_STATUS = 'onReview';
export const COMPLETED_STATUS = 'completed';
export const CLOSED_STATUS = 'closed';
export const CANCELED_STATUS = 'canceled';
export const REJECTED_STATUS = 'rejected';
export const OPEN_STATUS = 'open';
export const OPEN_STATUS_ID = 1;
export const COMPLETED_STATUS_ID = 2;
export const CANCELED_STATUS_ID = 17;
export const APPROVAL_REQUIRED_STATUS = 'approvalRequired';
export const REPORT_REPAIR_ALIAS = 'reportRepair';
export const LOW_PRIORITY = '1';

export const DATE_TYPES = {
  requestedDate: 'requestedDate',
  updatedAt: 'updatedAt',
  completedDate: 'completedDate',
};

export const OPENED_DATE_OPTIONS = [
  {
    label: 'Last activity',
    value: DATE_TYPES.updatedAt,
  },
  {
    label: 'Scheduled',
    value: DATE_TYPES.requestedDate,
  },
];

export const COMPLETED_DATE_OPTIONS = [
  {
    label: 'Completed',
    value: DATE_TYPES.completedDate,
  },
];

export const TYPES_OF_SERVICES = {
  PREVENTIVE_MAINTENANCE: '1',
  REPAIR: '2',
};

export const SERVICE_TYPE_MAPPER = {
  [TYPES_OF_SERVICES.PREVENTIVE_MAINTENANCE]: 'Preventive Maintenance',
  [TYPES_OF_SERVICES.REPAIR]: 'Repair',
};

export const SERVICE_TYPES = [
  {
    label: SERVICE_TYPE_MAPPER[TYPES_OF_SERVICES.PREVENTIVE_MAINTENANCE],
    value: TYPES_OF_SERVICES.PREVENTIVE_MAINTENANCE,
  },
  {
    label: SERVICE_TYPE_MAPPER[TYPES_OF_SERVICES.REPAIR],
    value: TYPES_OF_SERVICES.REPAIR,
  },
];

export const WO_PAYMENT_TYPES = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Invoice',
    value: 'invoice',
  },
  {
    label: 'Electronically',
    value: 'electronically',
  },
];

export const WO_PAYMENT_TYPES_MAPPER = {
  NONE: 'none',
  INVOICE: 'invoice',
};

export const PM_TYPE = '1';

export const MAPPED_SERVICE_TYPES = {
  1: 'Preventive Maintenance',
  2: 'Repair',
};

export const STATUSES_OF_DEVICES = {
  UP: 'Up',
  DOWN: 'Down',
};

export const DEVICE_STATUS = [
  {
    label: 'Up',
    value: STATUSES_OF_DEVICES.UP,
  },
  {
    label: 'Down',
    value: STATUSES_OF_DEVICES.DOWN,
  },
];

export const MAPPED_DEVICE_STATUSES = {
  1: 'Up',
  2: 'Down',
};

export const COST_OF_SERVICE_PERFORMED = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Warranty',
    value: 'warranty',
  },
  {
    label: 'Contract',
    value: 'contract',
  },
];

export const PRIORITY_MAP = {
  1: 'low',
  2: 'medium',
  3: 'high',
  4: 'critical',
};

export const PRIORITY = [
  {
    label: 'Low',
    value: '1',
  },
  {
    label: 'Medium',
    value: '2',
  },
  {
    label: 'High',
    value: '3',
  },
  {
    label: 'Critical',
    value: '4',
  },
];

export const REQUEST_REPAIR_STATUSES = {
  requested: 'requested',
  scheduled: 'scheduled',
  confirmed: 'confirmed',
  completed: 'completed',
  accepted: 'accepted',
  onReview: 'onReview',
  approvalRequired: 'approvalRequired',
  canceled: 'canceled',
};

export const JOB_STATUS = {
  ServiceRequested: 4,
  InProgress: 6,
};

export const JOB_STATUSES = [
  { value: JOB_STATUS.ServiceRequested, label: 'Service Requested' },
  { value: JOB_STATUS.InProgress, label: 'In Progress' },
];

export const REQUEST_WO_STATUSES = [
  'open',
  'serviceRequested',
  'inProgress',
  'scheduled',
  'onReview',
  'approvalRequired',
];

export const ERROR_ALIASES = {
  client: 'client',
  getMessage: 'getMessage',
  addMessage: 'addMessage',
  moreHistory: 'moreHistory',
  initChat: 'initChat',
  accessToken: 'accessToken',
};

export const CREATE_WO_KEY = 'create_wo';
export const SCHEDULE_WO_KEY = 'schedule_wo_appointment';
export const CONFIRM_WO_KEY = 'confirm_wo_appointment';
export const COMPLETE_WO_KEY = 'complete_wo';

export const REPORT_REPAIR_FLOW = 'reportRepair';
export const REQUEST_REPAIR_FLOW = 'RequestRepair';
export const REQUEST_REPAIR_AREA_FLOW = 'RequestRepairAreas';
export const REQUEST_REPAIR_BARRISTER = 'RequestRepairBarrister';
export const DEVICE_APPROVAL_FLOW = 'RequestRepairApproval';
export const AREA_APPROVAL_FLOW = 'RequestRepairAreasApproval';
export const REQUEST_REPAIR_FLOW_DW = 'RequestRepairDW';
export const REQUEST_REPAIR_AREA_FLOW_DW = 'RequestRepairAreasDW';
export const REQUEST_REPAIR_FLOW_PATTERSON = 'RequestRepairPatterson';

export const LINEAR_FLOW_PROP = 'isLinear';

export const OPEN_STATUSES = [1, 4, 5, 6, 7];
export const WO_COUNTER_STATUSES = [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
export const WO_LIST_URL = '/app/work-orders';
export const ADMIN_WO_LIST_URL = '/work-orders';

export const INTERMED_FLOW = 'RequestRepairIntermed';
export const INTERMED_FORM_KEY = 'intermed_create_wo';
export const INTERMED_VIEW_WO_FK = 'intermed_view_wo';

export const M3_FLOW = 'RequestRepairM3';
export const M3_FORM_KEY = 'create_wo_m3';
export const PATTERSON_FORM_KEY = 'create_wo_patterson';
export const WO_REMOVED_STORAGE_KEY = 'wo-removed-ids';
export const WO_REMOVED_STORAGE_TTL = 1000 * 60 * 60;

export const ARRIVE_WINDOW = {
  EXACT: 0,
  '30_MIN': 30,
  '1_HOUR': 30 * 2,
  '2_HOURS': 30 * 2 * 2,
  '4_HOURS': 30 * 2 * 4,
  '8_HOURS': 30 * 2 * 8,
};

export const ARRIVE_WINDOW_OPTIONS = [
  { value: ARRIVE_WINDOW.EXACT, label: 'Exact' },
  { value: ARRIVE_WINDOW['30_MIN'], label: '30 minutes' },
  { value: ARRIVE_WINDOW['1_HOUR'], label: '1 hour' },
  { value: ARRIVE_WINDOW['2_HOURS'], label: '2 hours' },
  { value: ARRIVE_WINDOW['4_HOURS'], label: '4 hours' },
  { value: ARRIVE_WINDOW['8_HOURS'], label: '8 hours' },
];

export const TECHNICIAN_FAVORITE_TIME_SETTING = 'technician_favorite_time';
export const TECHNICIAN_SHARE_WO_NOTES_SETTING = 'allow_to_share_wo_notes';

export const WO_STRATEGY = {
  DEVICE: 'device',
  AREA: 'area',
};

export const WO_STRATEGY_ID = {
  DEVICE: 1,
  AREA: 2,
};

export const WO_STRATEGIES_OPTIONS = [
  { value: WO_STRATEGY.DEVICE, label: 'Device' },
  { value: WO_STRATEGY.AREA, label: 'Area' },
];

export const WO_CHAT_CHANNEL_ALL = 'ALL';
