import React, { Fragment, lazy, Suspense, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import LoadingPlaceholder from '@uptime/shared/components/LoadingPlaceholder';

import AuthContext from './context/AuthContext';
import { HOME_PATH, WO_URLS } from './shared/constants/urls';
import Forbidden from './shared/components/Forbidden';
import SplashScreen from './shared/components/SplashScreen';
import usePermissions from './shared/hooks/usePermissions';
import useClearCache from './shared/hooks/useClearCache';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout/Layout';
import Login from './components/CognitoLogin/Login';
import Callback from './components/CognitoLogin/Callback';
import Activation from './components/CognitoLogin/Activation/Activation';
import JwtLogin from './containers/JwtLogin/JwtLoginContainer';

import './App.scss';

const AccountView = lazy(() => import('./containers/Account/AccountContainer'));
const TeamView = lazy(() => import('./containers/Account/Team'));

const CheckListView = lazy(() => import('./containers/CheckList/CheckListContainer'));
const CheckListRunView = lazy(() => import('./containers/CheckList/CheckListRunContainerWithTitle'));
const CheckListUploadPreviewView = lazy(() => import('./containers/CheckList/CheckListUploadPreview'));
const ComplianceView = lazy(() => import('./containers/CheckList/ComplianceContainer'));

const ContractorInvitationView = lazy(() => import('./containers/Technician/ContractorInvitationContainer'));
const HomePageView = lazy(() => import('./containers/HomePage'));
// TODO: hide dashboard for now(PROD-5810), code would need to be removed in the future
// const DashboardView = lazy(() => import('./containers/Dashboard'));
const DashboardCompliance = lazy(() => import('./components/Dashboard/partials/DashboardCompliance'));
const DeviceView = lazy(() => import('./containers/Devices/DeviceViewContainer'));
const DevicesView = lazy(() => import('./containers/Devices/DevicesContainer'));
const DeviceCreateView = lazy(() => import('./containers/Devices/DeviceCreateContainer'));
const DocumentsView = lazy(() => import('./containers/Documents/DocumentsContainer'));
const ExploDashboardView = lazy(() => import('./containers/Explo/ExploContainer'));
const FacilitiesView = lazy(() => import('./containers/Facilities/FacilitiesContainer'));
const FacilityView = lazy(() => import('./containers/Facilities/FacilityViewContainer'));
const FacilityWizardNewVersionView = lazy(() => import('./containers/Facilities/FacilityWizardContainer'));
const HubRouteView = lazy(() => import('./components/WorkOrders/HubRoute'));
const LegacyView = lazy(() => import('./containers/Legacy/Legacy'));

const LogListView = lazy(() => import('./containers/Logs/LogListContainer'));
const LogsUploadPreviewView = lazy(() => import('./containers/Logs/UploadPreview'));
const LogsComplianceView = lazy(() => import('./containers/Logs/Compliance'));
const MarketplaceContainerView = lazy(() => import('./containers/Marketplace/MarketplaceContainer'));
const MarketplaceSearchView = lazy(() => import('./containers/Marketplace/MarketplaceSearchContainer'));
const MarketplaceViewContainerView = lazy(() => import('./containers/Marketplace/MarketplaceViewContainer'));
const MarketplaceWizardView = lazy(() => import('./containers/Marketplace/MarketplaceWizardContainer'));
const QuickStartView = lazy(() => import('./containers/QuickStart/QuickStartContainer'));
const ReportRepairView = lazy(() => import('./components/WorkOrders/ReportRepair'));
const RequestRepairView = lazy(() => import('./components/WorkOrders/RequestRepair'));

const ServiceProviderListView = lazy(
  () => import('./containers/ServiceProviders/ServiceProviderListContainer')
);
const ServiceProvidersAssignmentsView = lazy(
  () => import('./containers/ServiceProvidersAssignments/ServiceProvidersAssignmentsContainer')
);
const ServiceProviderWizardView = lazy(
  () => import('./containers/ServiceProviders/ServiceProviderWizardContainer.js')
);
const ServiceProviderEditWizardView = lazy(
  () => import('./containers/ServiceProviders/ServiceProviderEditWizardContainer')
);
const ServiceProviderView = lazy(() => import('./containers/ServiceProviders/ServiceProviderViewContainer'));

const StripeView = lazy(() => import('./containers/Account/StripeContainer'));
const SystemsView = lazy(() => import('./containers/Systems/SystemsContainer'));

const TechnicianWizardView = lazy(() => import('./containers/Account/TechnicianWizardContainer'));
const TechnicianEditingWizardView = lazy(
  () => import('./containers/Account/TechnicianEditingWizardContainer')
);
const TechnicianMarketplaceView = lazy(() => import('./containers/Technician/MarketplaceContainer'));
const TechnicianFacilitiesView = lazy(() => import('./containers/Technician/FacilitiesContainer'));
const TechnicianDevicesView = lazy(() => import('./containers/Technician/DevicesContainer'));
const TechnicianFacilityDetailsView = lazy(() => import('./containers/Technician/FacilityDetailsContainer'));
const TechnicianDeviceDetailsView = lazy(() => import('./containers/Technician/DeviceDetailsContainer'));
const TechnicianTeamView = lazy(() => import('./containers/Technician/TeamContainer'));

const WorkOrderList = lazy(() => import('./containers/WorkOrders/WorkOrderContainerWithTitle'));
const WOView = lazy(() => import('./components/WorkOrders/View'));
const WorkOrderSummaryView = lazy(() => import('./containers/WorkOrders/WorkOrderSummaryContainer'));
const WORedirectView = lazy(() => import('./components/WorkOrders/View/WORedirect'));
const QRCodeViewDevice = lazy(() => import('./components/QRCode/ViewDevice'));

const Lms = lazy(() => import('./containers/Lms'));

const JWT_LOGIN = '/app/auth/:type';

const App = () => {
  const { isStaff, isTopLevelFacility, isRepairer } = useContext(AuthContext);

  const {
    facilityManagementPermission,
    technicianManagementPermission,
    technicianAssignmentsManagementPermission,
    logsManagementPermission,
    tasksManagementPermission,
    runTasksPermission,
    hasAnyExploDashboardPermission,
    docHubDeviceListPermission,
    docHubDeviceManagementPermission,
    docHubFacilityListPermission,
    docHubFacilityManagementPermission,
    docHubPersonalManagementPermission,
    techTeamManagementPermission,
    techMarketplaceInfoEditPermission,
    techAddFacilityPermission,
    commonPermission,
    settingsPermission,
    technicianCommonPermission,
    viewCoursesPermission,
  } = usePermissions();

  useClearCache();

  return (
    <div className="application">
      <Switch>
        <Route path="/app/activation" exact component={Activation} />
        <Route path="/app/callback" exact component={Callback} />
        <Route path="/app/Login" exact component={Login} />

        <Route path={JWT_LOGIN} component={JwtLogin} />

        <Layout>
          <Fragment>
            <SplashScreen />

            <Suspense fallback={<LoadingPlaceholder />}>
              <Switch>
                <PrivateRoute
                  path="/app/documents/:category"
                  component={DocumentsView}
                  isAllowed={
                    docHubDeviceListPermission ||
                    docHubFacilityListPermission ||
                    docHubDeviceManagementPermission ||
                    docHubFacilityManagementPermission ||
                    docHubPersonalManagementPermission
                  }
                />
                <PrivateRoute
                  path="/app/logs/compliance"
                  component={LogsComplianceView}
                  isAllowed={logsManagementPermission}
                />
                <PrivateRoute
                  path="/app/logs/uploads/:fileId"
                  component={LogsUploadPreviewView}
                  isAllowed={logsManagementPermission}
                />
                <PrivateRoute path="/app/logs" component={LogListView} isAllowed={logsManagementPermission} />
                <PrivateRoute
                  path="/app/dashboard/analytics/:dashboardID"
                  component={ExploDashboardView}
                  isAllowed={hasAnyExploDashboardPermission}
                />
                <PrivateRoute
                  path="/app/dashboard/compliance"
                  component={DashboardCompliance}
                  exact
                  isAllowed={runTasksPermission || logsManagementPermission}
                />
                <PrivateRoute
                  path="/app/dashboard"
                  render={() => <Redirect to="/app/dashboard/compliance" />}
                  isAllowed={!isRepairer}
                />
                {/* technician routes */}
                <PrivateRoute
                  exact
                  path="/app/technician/my-customers/facilities"
                  component={TechnicianFacilitiesView}
                  isAllowed={isRepairer}
                />
                <PrivateRoute
                  path="/app/technician/my-customers/facilities/:id"
                  component={TechnicianFacilityDetailsView}
                  isAllowed={isRepairer}
                />
                <PrivateRoute
                  exact
                  path="/app/technician/my-customers/devices"
                  component={TechnicianDevicesView}
                  isAllowed={isRepairer}
                />
                <PrivateRoute
                  path="/app/technician/my-customers/devices/:id"
                  component={TechnicianDeviceDetailsView}
                  isAllowed={isRepairer}
                />
                <PrivateRoute
                  exact
                  path="/app/technician/my-team"
                  component={TechnicianTeamView}
                  isAllowed={techTeamManagementPermission}
                />
                <PrivateRoute
                  exact
                  path="/app/technician/contractor-invite/:decision/:token"
                  component={ContractorInvitationView}
                  isAllowed={true}
                />
                <PrivateRoute
                  path="/app/edit-technician/:section"
                  component={TechnicianEditingWizardView}
                  isAllowed={techMarketplaceInfoEditPermission}
                />
                <PrivateRoute
                  path="/app/technician/marketplace"
                  component={TechnicianMarketplaceView}
                  isAllowed={isRepairer}
                />
                <PrivateRoute
                  path="/app/technician"
                  component={TechnicianWizardView}
                  isAllowed={isRepairer}
                />
                {/* -----> end; technician routes */}
                <PrivateRoute path="/app/quick-start" component={QuickStartView} isAllowed={!isRepairer} />
                <PrivateRoute
                  path="/app/stripe/oauth-callback"
                  component={StripeView}
                  isAllowed={!isRepairer}
                />
                <PrivateRoute
                  path="/app/devices/create"
                  component={DeviceCreateView}
                  isAllowed={commonPermission || technicianCommonPermission}
                />
                <PrivateRoute
                  path="/app/devices/update/:deviceId"
                  component={DeviceCreateView}
                  isAllowed={!isRepairer}
                />
                <PrivateRoute path="/app/devices/:deviceId" component={DeviceView} isAllowed={!isRepairer} />
                <PrivateRoute
                  path="/app/device/:assetId"
                  component={QRCodeViewDevice}
                  exact
                  isAllowed={!isRepairer}
                />
                <PrivateRoute path="/app/devices" component={DevicesView} isAllowed={!isRepairer} />
                <PrivateRoute path="/app/systems" component={SystemsView} isAllowed={!isRepairer} />
                <PrivateRoute
                  path="/app/check-list"
                  exact
                  render={() => <Redirect to="/app/check-list/run" />}
                  isAllowed={!isRepairer}
                />
                <PrivateRoute
                  path="/app/check-list/compliance"
                  component={ComplianceView}
                  isAllowed={runTasksPermission}
                />
                <PrivateRoute
                  path="/app/check-list/builder"
                  component={CheckListView}
                  isAllowed={tasksManagementPermission}
                />
                <PrivateRoute
                  path="/app/check-list/run/:taskId"
                  component={CheckListRunView}
                  isAllowed={runTasksPermission}
                />
                <PrivateRoute
                  path="/app/check-list/run"
                  component={CheckListRunView}
                  isAllowed={runTasksPermission}
                />
                <PrivateRoute
                  path="/app/tasks/:runTaskId/uploads"
                  component={CheckListUploadPreviewView}
                  isAllowed={runTasksPermission}
                />
                <PrivateRoute
                  path="/app/facilities/create"
                  component={FacilityWizardNewVersionView}
                  isAllowed={facilityManagementPermission || techAddFacilityPermission}
                />
                <PrivateRoute
                  path="/app/facilities/update/:facilityId"
                  component={FacilityWizardNewVersionView}
                  isAllowed={facilityManagementPermission}
                />
                <PrivateRoute
                  path="/app/facilities/:facilityId"
                  component={FacilityView}
                  isAllowed={!isRepairer}
                />
                <PrivateRoute path="/app/facilities" component={FacilitiesView} isAllowed={!isRepairer} />
                <PrivateRoute
                  path={[
                    '/app/service-providers/edit/:technicianId/:serviceProviderId/:contractId',
                    '/app/service-providers/edit/:technicianId/:serviceProviderId',
                  ]}
                  component={ServiceProviderEditWizardView}
                  isAllowed={!isRepairer}
                />
                <PrivateRoute
                  path="/app/service-providers/:technicianId/:serviceProviderId"
                  component={ServiceProviderView}
                  isAllowed={!isRepairer}
                />
                <PrivateRoute
                  path="/app/service-providers/create"
                  component={ServiceProviderWizardView}
                  isAllowed={technicianManagementPermission}
                />
                <PrivateRoute
                  path="/app/service-providers/assignments/"
                  component={ServiceProvidersAssignmentsView}
                  isAllowed={technicianAssignmentsManagementPermission}
                />
                <PrivateRoute
                  path="/app/service-providers"
                  component={ServiceProviderListView}
                  isAllowed={!isRepairer}
                />
                <PrivateRoute
                  path="/app/settings/account/"
                  render={() => <AccountView isAccountTab />}
                  isAllowed={settingsPermission || technicianCommonPermission}
                />
                <PrivateRoute
                  path="/app/settings/business"
                  exact
                  render={() => <AccountView isBusinessTab />}
                  isAllowed={settingsPermission || technicianCommonPermission}
                />

                <PrivateRoute
                  exact
                  path="/app/settings/business/team"
                  render={() => <TeamView />}
                  isAllowed={isTopLevelFacility}
                />

                <PrivateRoute
                  path="/app/settings/system/"
                  render={() => <AccountView isSystemTab />}
                  isAllowed={!isStaff || docHubDeviceManagementPermission}
                />
                <PrivateRoute
                  path="/app/settings"
                  exact
                  render={() => <Redirect to="/app/settings/account/" />}
                  isAllowed={true}
                />
                <PrivateRoute
                  path="/app/marketplace/search"
                  component={MarketplaceSearchView}
                  isAllowed={technicianManagementPermission || techTeamManagementPermission}
                />
                <PrivateRoute
                  path="/app/marketplace/add/:userId"
                  component={MarketplaceWizardView}
                  isAllowed={technicianManagementPermission || techTeamManagementPermission}
                />
                <PrivateRoute
                  path="/app/marketplace/:userId"
                  component={MarketplaceViewContainerView}
                  isAllowed={technicianManagementPermission || techTeamManagementPermission}
                />
                <PrivateRoute
                  path="/app/marketplace"
                  component={MarketplaceContainerView}
                  isAllowed={technicianManagementPermission || techTeamManagementPermission}
                />
                <PrivateRoute
                  path="/app/work-orders/hub"
                  component={HubRouteView}
                  isAllowed={commonPermission || technicianCommonPermission}
                />
                <PrivateRoute
                  path={['/app/work-orders/report/:deviceId', '/app/work-orders/report-update/:processId']}
                  component={ReportRepairView}
                  isAllowed={commonPermission || technicianCommonPermission}
                />
                <PrivateRoute
                  path="/app/work-orders/request/:strategy/:flowId"
                  component={RequestRepairView}
                  isAllowed={commonPermission || technicianCommonPermission}
                />
                <PrivateRoute
                  path="/app/request-email/:processId"
                  component={WORedirectView}
                  isAllowed={true}
                />
                <PrivateRoute
                  path="/app/work-orders/request-view/:processId"
                  component={WOView}
                  isAllowed={commonPermission || technicianCommonPermission}
                />
                <PrivateRoute
                  path="/app/work-orders/summary/:processId"
                  component={WorkOrderSummaryView}
                  isAllowed={commonPermission || technicianCommonPermission}
                />
                <PrivateRoute
                  path="/app/work-orders"
                  component={WorkOrderList}
                  isAllowed={commonPermission || technicianCommonPermission}
                />
                <PrivateRoute path="/app/learning" component={Lms} isAllowed={viewCoursesPermission} />
                <PrivateRoute
                  path={[
                    ...WO_URLS,
                    '/app/device/default/export-device',
                    '/app/device/default/upload',
                    '/app/device/default/index',
                    '/app/device/default/view-sub-device',
                    '/app/device/default/report-work-order',
                    '/app/device/default/export-devices-history',
                    '/app/repairer/default/service-list',
                    '/app/repairer/default/add-location-cover',
                    '/app/site/update-card',
                    '/app/site/notifications',
                    '/app/site/update-bank-details',
                    '/app/frequency/default/index',
                    '/app/frequency/default/create',
                    '/app/frequency/default/update',
                    '/app/frequency/default/view',
                    '/app/site/change-availability',
                  ]}
                  component={LegacyView}
                  isAllowed={commonPermission || technicianCommonPermission}
                />
                <PrivateRoute
                  path={HOME_PATH}
                  render={() => (isRepairer ? <Redirect to="/app/work-orders" /> : <HomePageView />)}
                  isAllowed={commonPermission || technicianCommonPermission}
                />
                <PrivateRoute path="/app/forbidden" component={Forbidden} isAllowed={true} />
                <PrivateRoute
                  path={['/app/site/dashboard', '/app/']}
                  render={() => <Redirect to={isRepairer ? '/app/work-orders' : HOME_PATH} />}
                  isAllowed={true}
                />
                {/* if any incorrect address */}
                <Redirect to={isRepairer ? '/app/work-orders' : HOME_PATH} />
              </Switch>
            </Suspense>
          </Fragment>
        </Layout>
      </Switch>
    </div>
  );
};

export default App;

const componentsList = [
  './containers/HomePage',
  './components/Dashboard/partials/DashboardCompliance',
  './containers/Devices/DevicesContainer',
  './containers/Devices/DeviceViewContainer',
  './containers/Devices/DeviceCreateContainer',
  './containers/Documents/DocumentsContainer',
  './containers/Explo/ExploContainer',
  './containers/Facilities/FacilitiesContainer',
  './containers/Facilities/FacilityViewContainer',
  './containers/Facilities/FacilityWizardContainer',
  './containers/ServiceProviders/ServiceProviderListContainer',
  './containers/ServiceProvidersAssignments/ServiceProvidersAssignmentsContainer',
  './containers/ServiceProviders/ServiceProviderWizardContainer.js',
  './containers/ServiceProviders/ServiceProviderEditWizardContainer',
  './containers/ServiceProviders/ServiceProviderViewContainer',
  './containers/Account/TechnicianWizardContainer',
  './containers/Account/TechnicianEditingWizardContainer',
  './containers/WorkOrders/WorkOrderContainerWithTitle',
  './components/WorkOrders/View',
  './containers/WorkOrders/WorkOrderSummaryContainer',
  './components/WorkOrders/View/WORedirect',
  './components/WorkOrders/RequestRepair',
  './components/CognitoLogin/CognitoLogin',
  './containers/JwtLogin/JwtLoginContainer',
];

if (import.meta.hot) {
  import.meta.hot.accept(componentsList, (arrayOfModules) => {});
}
