import { createTheme } from '@mui/material/styles';

/**
 * !!!IMPORTANT!!!
 * Please do not add any new colors without an agreement with our designer.
 * Right now, theme includes all the colors from Figma, so unless it was added
 * to the main Colors scheme in Figma, you must only use colors that are defined in here.
 */

const defaultTheme = createTheme({
  palette: {
    primary: {
      dark: '#5449D2',
      main: '#695EE4',
      light3: '#9E96ED',
      light2: '#CECBF6',
      light1: '#EFEEFC',
      standardSupport: '#09deff',
      premiumSupport: '#116ba7',
      repairDepot: '#f2c566',
      teleTech: '#be68eb',
    },
    secondary: {
      dark: '#150F57',
      dark1: '#C5C1F2',
      main: '#DEDCF9',
      light1: '#F8F8FF',
      light: '#F3F4F8',
    },
    success: {
      dark: '#2E7D32',
      main: '#4CAF50',
      light2: '#C8E6C9',
      light1: '#E8F5E9',
    },
    error: {
      dark: '#C62828',
      main: '#D32F2F',
      light3: '#EF5350',
      light2: '#FFCDD2',
      light1: '#FFEBEE',
    },
    info: {
      main: '#FAFAFA', // same as grey.100 but keeping for compatibility
    },
    warning: {
      dark: '#EF6C00',
      main: '#FF9800',
      light2: '#FFE0B2',
      light1: '#FFF3E0',
    },
    grey: {
      main: '#757575', // same as grey.600, keeping this for compatibility
      black: '#1B1B1B',
      white: '#FFFFFF',
      100: '#FAFAFA',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      1000: '#909090',
    },
    yellow: {
      dark: '#FFD600',
      main: '#FFF9C4',
    },
    tonalOffset: 0.6,
  },
});

export default defaultTheme;
