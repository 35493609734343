export const FEATURES = {
  COMMON: 'commonFeatures',
  SETTINGS: 'commonUserSettings',
  FACILITY_MANAGEMENT: 'facilityManagement',
  TECHNICIAN_MANAGEMENT: 'technicianManagement',
  TECHNICIAN_ASSIGNMENTS_MANAGEMENT: 'technicianAssignmentsManagement',
  LOGS_MANAGEMENT: 'logsManagement',
  TASKS_MANAGEMENT: 'tasksManagement',
  RUN_TASKS: 'runTasks',
  RESET_TASKS: 'resetTasks',
  WORK_ORDER_FACILITY_MANAGEMENT: 'workOrderFacilityManagement',
  DEVICE_DASHBOARDS: 'deviceDashboard',
  FINANCIALS_DASHBOARD: 'financialsDashboard',
  ACTIVITIES_DASHBOARD: 'activitiesDashboard',
  SERVICE_REQUESTS_DASHBOARD: 'serviceRequestsDashboard',
  WARRANTY_AND_CONTRACTS_DASHBOARD: 'warrantyAndContractsDashboard',
  DOC_HUB_DEVICE_MANAGEMENT: 'docHubDeviceManagement',
  DOC_HUB_DEVICE_LIST: 'docHubDeviceList',
  DOC_HUB_FACILITY_MANAGEMENT: 'docHubFacilityManagement',
  DOC_HUB_FACILITY_LIST: 'docHubFacilityList',
  DOC_HUB_PERSONAL_MANAGEMENT: 'docHubPersonalManagement',
  BULK_UPDATE: 'bulkUpdate',
  IOT: 'iot',
  VIEW_COURSES: 'viewFORxCourses',
};

export const TECHNICIAN_FEATURES = {
  TECH_TEAM_COMMON: 'techCommon',
  TECH_TEAM_MANAGEMENT: 'techTeamManagement',
  TECH_MARKETPLACE_INFO_EDIT: 'techMarketplaceInfoEdit',
  TECH_REASSIGN: 'techReassign',
  TECH_ADD_FACILITY: 'techAddFacility',
  TECH_CONTRACTS_EDIT: 'techContractsEdit',
  TECH_ACCOUNT_MANAGEMENT: 'techAccountManagement',
};
