import HomeIcon from '@mui/icons-material/Home';
import DevicesIcon from '@mui/icons-material/Devices';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SettingsIcon from '@mui/icons-material/Settings';

import usePermissions from 'shared/hooks/usePermissions';

import { CHECK_LIST_PATHNAME } from '../constants';

const formatCount = (count = 0) => (Number(count) > 99 ? '99+' : count);

export default ({
  activeRunTaskCount = 0,
  documentsCount = 0,
  woCount = 0,
  subMenuUrl,
  isTopLevelFacility,
  isStaff,
  hideBusinessTabs,
  logsCount = 0,
}) => {
  const {
    commonPermission,
    settingsPermission,
    tasksManagementPermission,
    runTasksPermission,
    logsManagementPermission,
    docHubDeviceListPermission,
    docHubDeviceManagementPermission,
    docHubFacilityListPermission,
    docHubFacilityManagementPermission,
    docHubPersonalManagementPermission,
    viewCoursesPermission,
  } = usePermissions();

  return [
    {
      url: 'home',
      title: 'Home',
      icon: <HomeIcon />,
      permission: commonPermission,
    },
    {
      url: 'facilities',
      title: 'Facilities',
      icon: <BusinessIcon />,
      permission: commonPermission,
    },
    {
      url: 'devices',
      title: 'Devices',
      icon: <DevicesIcon />,
      permission: commonPermission,
    },
    {
      url: 'check-list',
      title: 'Tasks',
      badge: subMenuUrl !== CHECK_LIST_PATHNAME ? formatCount(activeRunTaskCount) : undefined,
      icon: <PlaylistAddCheckIcon />,
      permission: tasksManagementPermission || runTasksPermission,
      children: [
        {
          url: 'check-list/builder',
          title: 'Builder',
          permission: tasksManagementPermission,
        },
        {
          url: 'check-list/run',
          title: 'To Dos',
          badge: subMenuUrl === CHECK_LIST_PATHNAME ? formatCount(activeRunTaskCount) : undefined,
          permission: runTasksPermission,
        },
      ],
    },
    {
      url: 'logs',
      title: 'Logs',
      icon: <ListAltIcon />,
      badge: formatCount(logsCount),
      permission: logsManagementPermission,
    },
    {
      url: 'work-orders',
      title: 'Service Requests',
      icon: <AssignmentIcon />,
      badge: formatCount(woCount),
      permission: commonPermission,
      // TODO: should be extended in future
      // badge: subMenuUrl !== WO_LIST_PATHNAME ? formatCount(woCount) : undefined,
      // children: [
      //   {
      //     url: 'work-orders',
      //     title: 'All',
      //     badge: subMenuUrl === WO_LIST_PATHNAME ? formatCount(woCount) : undefined,
      //     permission: commonPermission,
      //   }
      // ],
    },
    {
      url: 'service-providers',
      title: 'Technicians',
      icon: <PeopleIcon />,
      permission: commonPermission,
    },
    {
      url: 'documents/facility',
      title: 'Documents',
      icon: <LibraryBooksIcon />,
      badge: formatCount(documentsCount),
      permission:
        docHubDeviceListPermission ||
        docHubFacilityListPermission ||
        docHubDeviceManagementPermission ||
        docHubFacilityManagementPermission ||
        docHubPersonalManagementPermission,
    },
    {
      url: 'learning',
      title: 'Learning',
      icon: <SchoolIcon />,
      permission: viewCoursesPermission,
    },
    {
      url: 'settings',
      title: 'Settings',
      icon: <SettingsIcon />,
      permission: settingsPermission,
      children: [
        {
          url: 'settings/account',
          title: 'Account',
          permission: settingsPermission,
        },
        isTopLevelFacility && !hideBusinessTabs
          ? {
              url: 'settings/business',
              title: 'Business',
              permission: settingsPermission,
            }
          : null,
        !hideBusinessTabs &&
        (docHubDeviceManagementPermission ||
          (!isStaff && (docHubFacilityManagementPermission || docHubPersonalManagementPermission)))
          ? {
              url: 'settings/system',
              title: 'System',
              permission: settingsPermission,
            }
          : null,
      ],
    },
  ];
};
