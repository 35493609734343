import { onError } from '@apollo/client/link/error';
import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';
import { getAuthenticationHeader, sleep, trackExpiredSession } from '@uptime/shared/utils/general';

// TODO:C - replace 'process' by import.meta after migrating to VITE all projects
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (!process.env.REACT_APP_DEBUG || !process.env.VITE_DEBUG) return false;
  trackExpiredSession(networkError);

  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}.`)
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpPaymentLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_PAYMENT || process.env.VITE_GRAPHQL_PAYMENT,
});

const filesLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_FILE || process.env.VITE_GRAPHQL_FILE,
});

const devicesLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_DEVICE || process.env.VITE_GRAPHQL_DEVICE,
});

const newDevicesLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_NEW_DEVICE || process.env.VITE_GRAPHQL_NEW_DEVICE,
});

const userLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_USER || process.env.VITE_GRAPHQL_USER,
});

const facilityLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_FACILITY || process.env.VITE_GRAPHQL_FACILITY,
});

const goCheckItLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_GOCHECKIT || process.env.VITE_GRAPHQL_GOCHECKIT,
});

const marketplaceLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_MARKETPLACE || process.env.VITE_GRAPHQL_MARKETPLACE,
});

const flowLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_FLOW || process.env.VITE_GRAPHQL_FLOW,
});

const iotLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_IOT || process.env.VITE_GRAPHQL_IOT,
});

const authLink = setContext(async (GraphQLRequest, { headers }) => {
  const accessHeader = getAuthenticationHeader();

  return {
    headers: {
      ...headers,
      ...accessHeader,
    },
  };
});

export const userClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(userLink)]),
  fetchOptions: {
    mode: 'no-cors',
  },
  cache: new InMemoryCache({ addTypename: false }),
});

export const paymentClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(httpPaymentLink)]),
  fetchOptions: {
    mode: 'no-cors',
  },
  cache: new InMemoryCache(),
});

export const filesClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(filesLink)]),
  cache: new InMemoryCache({ addTypename: false }),
});

export const devicesClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(devicesLink)]),
  cache: new InMemoryCache({ addTypename: false }),
});

export const newDeviceClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(newDevicesLink)]),
  cache: new InMemoryCache(),
});

export const facilityClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(facilityLink)]),
  fetchOptions: {
    mode: 'no-cors',
  },
  cache: new InMemoryCache(),
});

export const goCheckItClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(goCheckItLink)]),
  cache: new InMemoryCache(),
});

export const marketplaceClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(marketplaceLink)]),
  cache: new InMemoryCache(),
});

export const flowClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(flowLink)]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export const iotClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(iotLink)]),
  cache: new InMemoryCache(),
});
