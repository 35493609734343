import { useContext } from 'react';

import { FEATURES, TECHNICIAN_FEATURES } from '@uptime/shared/constants/permissions';
import { checkPermission } from '@uptime/shared/utils/permissions';

import { PermissionsContext } from 'providers/PermissionsProvider';

const usePermissions = () => {
  const { permissions, setPermissions, hasPermissionsLoaded, setHasPermissionsLoaded } =
    useContext(PermissionsContext);

  // Common
  const commonPermission = checkPermission(FEATURES.COMMON, permissions);

  const settingsPermission = checkPermission(FEATURES.SETTINGS, permissions);

  // Dashboard
  const deviceDashboardPermission = checkPermission(FEATURES.DEVICE_DASHBOARDS, permissions);
  const financialsDashboardPermission = checkPermission(FEATURES.FINANCIALS_DASHBOARD, permissions);
  const activitiesDashboardPermission = checkPermission(FEATURES.ACTIVITIES_DASHBOARD, permissions);
  const serviceRequestsDashboardPermission = checkPermission(
    FEATURES.SERVICE_REQUESTS_DASHBOARD,
    permissions
  );
  const warrantyAndContractsDashboardPermission = checkPermission(
    FEATURES.WARRANTY_AND_CONTRACTS_DASHBOARD,
    permissions
  );

  // Facility
  const facilityManagementPermission = checkPermission(FEATURES.FACILITY_MANAGEMENT, permissions);

  // Iot
  // should be available for everyone for now
  const iotPermission = true;
  // const iotPermission = checkPermission(FEATURES.IOT, permissions);

  // Tasks
  const tasksManagementPermission = checkPermission(FEATURES.TASKS_MANAGEMENT, permissions);
  const runTasksPermission = checkPermission(FEATURES.RUN_TASKS, permissions);
  const resetTasksPermission = checkPermission(FEATURES.RESET_TASKS, permissions);

  // Logs
  const logsManagementPermission = checkPermission(FEATURES.LOGS_MANAGEMENT, permissions);

  // Work Orders / Service Requests
  const workOrderFacilityManagementPermission = checkPermission(
    FEATURES.WORK_ORDER_FACILITY_MANAGEMENT,
    permissions
  );

  // Learning
  const viewCoursesPermission = checkPermission(FEATURES.VIEW_COURSES, permissions);

  // Technicians
  const technicianManagementPermission = checkPermission(FEATURES.TECHNICIAN_MANAGEMENT, permissions);
  const technicianAssignmentsManagementPermission = checkPermission(
    FEATURES.TECHNICIAN_ASSIGNMENTS_MANAGEMENT,
    permissions
  );

  // Documents
  const docHubFacilityListPermission = checkPermission(FEATURES.DOC_HUB_FACILITY_LIST, permissions);
  const docHubFacilityManagementPermission = checkPermission(
    FEATURES.DOC_HUB_FACILITY_MANAGEMENT,
    permissions
  );

  const docHubDeviceListPermission = checkPermission(FEATURES.DOC_HUB_DEVICE_LIST, permissions);
  const docHubDeviceManagementPermission = checkPermission(FEATURES.DOC_HUB_DEVICE_MANAGEMENT, permissions);

  const docHubPersonalManagementPermission = checkPermission(
    FEATURES.DOC_HUB_PERSONAL_MANAGEMENT,
    permissions
  );

  // Bulk Update
  const bulkUpdatePermission = checkPermission(FEATURES.BULK_UPDATE, permissions);

  // Technician permissions
  const technicianCommonPermission = checkPermission(TECHNICIAN_FEATURES.TECH_TEAM_COMMON, permissions);
  const techTeamManagement = checkPermission(TECHNICIAN_FEATURES.TECH_TEAM_MANAGEMENT, permissions);
  const techMarketplaceInfoEdit = checkPermission(
    TECHNICIAN_FEATURES.TECH_MARKETPLACE_INFO_EDIT,
    permissions
  );
  const techReassign = checkPermission(TECHNICIAN_FEATURES.TECH_REASSIGN, permissions);
  const techAddFacility = checkPermission(TECHNICIAN_FEATURES.TECH_ADD_FACILITY, permissions);
  const techContractsEdit = checkPermission(TECHNICIAN_FEATURES.TECH_CONTRACTS_EDIT, permissions);
  const techAccountManagement = checkPermission(TECHNICIAN_FEATURES.TECH_ACCOUNT_MANAGEMENT, permissions);

  return {
    setPermissions,
    hasPermissionsLoaded,
    setHasPermissionsLoaded,

    commonPermission,
    settingsPermission,

    deviceDashboardPermission,
    financialsDashboardPermission,
    activitiesDashboardPermission,
    serviceRequestsDashboardPermission,
    warrantyAndContractsDashboardPermission,

    facilityManagementPermission,

    iotPermission,

    tasksManagementPermission,
    runTasksPermission,
    resetTasksPermission,

    logsManagementPermission,

    viewCoursesPermission,

    workOrderFacilityManagementPermission,

    technicianCommonPermission,
    technicianManagementPermission,
    technicianAssignmentsManagementPermission,

    docHubFacilityManagementPermission,
    docHubFacilityListPermission,
    docHubDeviceManagementPermission,
    docHubDeviceListPermission,
    docHubPersonalManagementPermission,

    hasAnyDocumentsPermission:
      docHubFacilityManagementPermission ||
      docHubFacilityListPermission ||
      docHubDeviceManagementPermission ||
      docHubDeviceListPermission ||
      docHubPersonalManagementPermission,

    hasAnyExploDashboardPermission:
      deviceDashboardPermission ||
      financialsDashboardPermission ||
      activitiesDashboardPermission ||
      serviceRequestsDashboardPermission ||
      warrantyAndContractsDashboardPermission,

    bulkUpdatePermission,

    // == tech permissions ==
    techTeamManagementPermission: techTeamManagement,
    techMarketplaceInfoEditPermission: techMarketplaceInfoEdit,
    techReassignPermission: techReassign,
    techAddFacilityPermission: techAddFacility,
    techContractsEditPermission: techContractsEdit,
    techAccountManagementPermission: techAccountManagement,
  };
};

export default usePermissions;
