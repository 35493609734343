import qs from 'query-string';
import { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Button, Typography, useTheme, CircularProgress, useMediaQuery } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { clearExternalState, clearJwtState } from '@uptime/shared/utils/general';

import { useOnMount, useOnUpdate } from 'libs/hooks';
import { useCognito } from 'libs/providers/CognitoProvider';
import Input from 'libs/react-hook-form/Input';

import * as BaseLogin from 'libs/components/BaseLoginForm';
import ShowPassword from 'libs/components/ShowPassword';

import ActivationSchema from './Activation.schema';

const useStyles = makeStyles((theme) => ({
  login: {
    background: theme.palette.grey[1000],
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
}));

const Activation = () => {
  const { resetPassword } = useCognito();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const [error, setError] = useState('');
  const [completedResetPassword, setCompletedResetPassword] = useState(false);

  const { code } = qs.parse(location.search, { decode: true }) as { code: string };

  const onlySmallAndMedium = useMediaQuery(theme.breakpoints.between('xs', 1540));

  const [visibility, setVisibility] = useState({
    password: false,
    confirm: false,
  });

  const handleVisibility = useCallback(
    (name) => () =>
      setVisibility((state) => ({
        ...state,
        [name]: !state[name],
      })),
    []
  );

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      username: null,
      password: null,
      confirm: null,
    },
    mode: 'onChange',
    resolver: yupResolver(ActivationSchema),
  });

  const [confirm, password] = watch(['confirm', 'password']);

  useOnUpdate(() => {
    trigger('confirm');
    trigger('password');
  }, [confirm, password]);

  const handleConfirm = useCallback(async ({ password, username }) => {
    setError('');

    try {
      clearJwtState();

      await resetPassword(username.trim(), code.trim(), password.trim());

      clearExternalState();
      setCompletedResetPassword(true);
    } catch (e) {
      // @ts-ignore
      setError(e.message || 'Oops, something went wrong');
    }
  }, []);

  useOnMount(() => {
    if (!code) {
      setError('Invalid activation url');
    }
  });

  return (
    <Box className={classes.login}>
      <Box mt={onlySmallAndMedium ? 4 : 10} mb={6}>
        <BaseLogin.Form onSubmit={handleSubmit(handleConfirm)}>
          <BaseLogin.Title>Create Account</BaseLogin.Title>

          {!completedResetPassword && (
            <Box component={Grid} container justifyContent="center" p={5}>
              <Box component={Grid} item xs={12} textAlign="left">
                <Typography color={theme.palette.secondary.dark}>Please create your account</Typography>
              </Box>

              <Box my={2} component={Grid} item xs={12} textAlign="left" fontSize="0.9rem">
                <strong>Password requirements:</strong> At least 8 characters, a lowercase letter, an
                uppercase letter, a number.
              </Box>

              <BaseLogin.Error>{error}</BaseLogin.Error>

              <BaseLogin.Input testid="username">
                <Input name="username" label="Your username" fullWidth autoComplete="off" control={control} />
              </BaseLogin.Input>

              <BaseLogin.Input testid="password">
                <Input
                  name="password"
                  type={visibility.password ? 'text' : 'password'}
                  label="Enter new password"
                  fullWidth
                  autoComplete="new-password"
                  control={control}
                  endAdornment={
                    <ShowPassword visible={visibility.password} onShow={handleVisibility('password')} />
                  }
                />
              </BaseLogin.Input>

              <BaseLogin.Input testid="confirmPassword">
                <Input
                  name="confirm"
                  type={visibility.confirm ? 'text' : 'password'}
                  label="Confirm new password"
                  fullWidth
                  autoComplete="new-password"
                  control={control}
                  endAdornment={
                    <ShowPassword visible={visibility.confirm} onShow={handleVisibility('confirm')} />
                  }
                />
              </BaseLogin.Input>

              <BaseLogin.Button testid="submitButton">
                <Button type="submit" variant="contained" fullWidth disabled={isSubmitting || !code}>
                  {isSubmitting && (
                    <Box ml={-3} mr={1.5} mt={0.75}>
                      <CircularProgress size={12} />
                    </Box>
                  )}

                  <Typography>Create My Account</Typography>
                </Button>
              </BaseLogin.Button>
            </Box>
          )}

          {completedResetPassword && (
            <Box component={Grid} container justifyContent="center" p={5}>
              <Grid container alignItems="center">
                <CheckIcon color="success" />
                &nbsp;&nbsp;
                <Typography>Your Account created successfully</Typography>
              </Grid>
              <BaseLogin.Button testid="goToLoginPagebutton">
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  onClick={() => history.push('/app/login')}
                >
                  <Typography>Sign In</Typography>
                </Button>
              </BaseLogin.Button>
            </Box>
          )}
        </BaseLogin.Form>
      </Box>
    </Box>
  );
};

export default Activation;
