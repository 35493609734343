import React from 'react';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

import { setLastLogin } from '@uptime/shared/graphql/users';
import { userClient } from 'apolloClientConfiguration';

import { useOnMount } from 'libs/hooks';
import { useCognito } from 'libs/providers/CognitoProvider';

const useStyles = makeStyles((theme) => ({
  login: {
    background: theme.palette.grey[200],
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
}));

const Callback = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { isAuthorized, getLastLoginPath, getUser } = useCognito();

  const [lastLogin] = useMutation(setLastLogin, {
    client: userClient,
    onCompleted() {
      window.location.href = getLastLoginPath();
    },
  });

  useOnMount(() => {
    if (!isAuthorized) return;

    const user = getUser();

    lastLogin({ variables: { userId: Number(user?.userId) } });
  });

  return (
    <Box className={classes.login}>
      <Box
        width="100%"
        height="90vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography component={Box} my={2} color={theme.palette.grey[900]}>
          Authenticating
        </Typography>
        {/* @ts-ignore */}
        <CircularProgress color="grey" size={50} thickness={1} />
      </Box>
    </Box>
  );
};

export default Callback;
