import { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { JWT_TOKEN_STORAGE_KEY, MAX_ALLOWED_TIMES_OF_UI_REFRESH_TOKEN } from '@uptime/shared/constants';
import { clearAuthData, getExpirationUIToken } from '@uptime/shared/utils/general';
import { JWT_INIT_STATE } from 'context/JwtContext';
import { loginAsUnifiedImagingToken } from 'shared/requests/unified-imaging';

const useJwtInitialization = () => {
  const history = useHistory();
  const [state, setState] = useState(JWT_INIT_STATE);

  useEffect(
    () => {
      const storage = localStorage.getItem(JWT_TOKEN_STORAGE_KEY);

      if (!storage) return;

      setState({
        auth: JSON.parse(storage),
        shouldShowUIWaring: false,
        authorized: true,
      });
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    const { auth } = state;

    if (!auth) {
      return;
    }

    const { UI } = auth;

    if (!UI) {
      return;
    }

    const { expireAt } = getExpirationUIToken(auth);

    const { times, identifier } = UI;

    if (times > MAX_ALLOWED_TIMES_OF_UI_REFRESH_TOKEN) {
      setState({
        ...state,
        shouldShowUIWarning: true,
      });

      clearAuthData();

      return;
    }

    const interval = setInterval(
      async () => {
        try {
          const auth = await loginAsUnifiedImagingToken({ identifier });

          setState({
            ...state,
            auth,
          });
        } catch (e) {
          clearAuthData();

          setState({
            ...state,
            error: get(e, 'graphQLErrors[0].message', 'Oops, something went wrong'),
          });
        }
      },
      expireAt * 1000 - Date.now()
    );

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [state.auth]);

  const signOut = useCallback(
    () => state.authorized && history.push('/app/login'),
    // eslint-disable-next-line
    []
  );

  return {
    ...state,
    signOut,
  };
};

export default useJwtInitialization;
