export const HOME_PATH = '/app/home';
export const COMPLIANCE_URL = '/app/check-list/compliance';
export const SCHEDULER_URL = '/app/request/default/scheduler';

export const WO_URLS = [
  '/app/request/default/pending',
  '/app/request/default/incomplete',
  '/app/request/default/complete',
  '/app/request/default/declined',
  '/app/request/default/create',
  '/app/request/default/create-without-repairer',
  '/app/request/default/review-work',
  '/app/request/default/review-cost',
];
